


















































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { DeliveryMatchingDraft } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDraft'
import {
  DeliveryMatchingParticipants,
  DeliveryMatchingStatus,
  DeliveryMatchingSort,
} from '@/enums'
import { IDeliveryDraft } from '@/interfaces'

export default defineComponent({
  name: 'DeliveryMatchingSelect',
  components: { DeliveryMatchingDraft, LoadingSpinner },
  props: {
    factoryDeliveries: {
      type: Array as PropType<null | IDeliveryDraft[]>,
      default: null,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const vm = getCurrentInstance()?.proxy

    const sortBy = ref(DeliveryMatchingSort.DeliveryId)
    const filterByStatus = ref({
      text: vm?.$i18n.t(
        `deliveryMatching.statuses.${DeliveryMatchingStatus.NoMatch}`
      ),
      value: DeliveryMatchingStatus.NoMatch,
    })
    const menu = ref(false)

    const sortList = computed(() =>
      Object.values(DeliveryMatchingSort).map((item: string) => ({
        text: vm?.$i18n.t(`deliveryMatching.filters.${item}.label`),
        value: item,
      }))
    )
    const filterByStatusList = computed(() =>
      Object.values(DeliveryMatchingStatus).map((item: string) => ({
        text: vm?.$i18n.t(`deliveryMatching.statuses.${item}`),
        value: item,
      }))
    )

    const handleStatusFilter = (value: DeliveryMatchingStatus) => {
      if (!value) {
        items.value = props.factoryDeliveries
      } else if (value === DeliveryMatchingStatus.NoMatch) {
        items.value = (props.factoryDeliveries || []).filter(
          (delivery) => !delivery.status
        )
      } else {
        items.value = (props.factoryDeliveries || []).filter(
          (delivery) => delivery.status === value
        )
      }
    }

    const items = ref(props.factoryDeliveries)

    watch(
      () => props.factoryDeliveries,
      () => {
        handleStatusFilter(filterByStatus.value.value)
      },
      { deep: true }
    )

    const handleFactoryDeliverySelect = (delivery: IDeliveryDraft) => {
      vm?.$emit('select', delivery)
      menu.value = false
    }

    return {
      sortBy,
      sortList,
      filterByStatus,
      filterByStatusList,
      items,
      menu,
      handleFactoryDeliverySelect,
      DeliveryMatchingParticipants,
      DeliveryMatchingStatus,
      handleStatusFilter,
    }
  },
})
