




































































































import {
  defineComponent,
  getCurrentInstance,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import moment from 'moment'
import { DateRange } from '@/components/molecules/DateRange'
import { Dropdown } from '@/components/molecules/Dropdown'
import { IDeliveryMatchingFilters } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'
import { DeliveryMatchingStatus } from '@/enums'

export default defineComponent({
  components: {
    DateRange,
    Dropdown,
  },
  props: {
    materialTypeCodes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    filters: {
      type: Object as PropType<IDeliveryMatchingFilters>,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance()?.proxy

    const formItems = ref<IDeliveryMatchingFilters>(props.filters)
    const isDateRangeMenuOpen = ref(false)

    watch(
      () => props.filters,
      () => {
        formItems.value = props?.filters || {}
      },
      { deep: true }
    )

    const dateRangeMax = moment().add(1, 'months').endOf('month').unix()

    const handleUpdateFilters = () => {
      emit('change', formItems)
    }

    const statuses = Object.values(DeliveryMatchingStatus).map((status) => {
      return {
        text: vm?.$i18n?.t(`deliveryMatching.statuses.${status}`),
        value: status,
      }
    })

    return {
      formItems,
      dateRangeMax,
      handleUpdateFilters,
      DeliveryMatchingStatus,
      isDateRangeMenuOpen,
      statuses,
    }
  },
})
