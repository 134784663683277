























import {
  defineComponent,
  SetupContext,
  computed,
  onMounted,
} from '@vue/composition-api'
import { DeliveryManagementDetails } from '@/components/delivery/DeliveryManagement/DeliveryManagementDetails'
import { DeliveryManagementFilters } from '@/components/delivery/DeliveryManagement/DeliveryManagementFilters'
import useDeliveryManagement, {
  IDeliveryManagementFilters,
} from '@/components/delivery/DeliveryManagement/DeliveryManagementPage/composable'
import { PageTitle } from '@/components/atoms/PageTitle'
import { DeliveryFileType } from '@/enums'
import { IDeliveryDocument } from '@/interfaces'

export default defineComponent({
  name: 'DeliveryManagementPage',
  components: {
    PageTitle,
    DeliveryManagementDetails,
    DeliveryManagementFilters,
  },
  setup(_, context: SetupContext) {
    const {
      user,
      loadDeliveryManagementDetails,
      generateCSVExport,
      handlePDFDownload,
      getFiltersFromQuery,
      getLoading,
      getDeliveriesAggregateInfo,
      getDeliveriesItems,
      getLastEvaluatedKey,
      getDefaultFilters,
      updateQuery,
    } = useDeliveryManagement(context)

    const organisationId = computed(() => user.value?.organisationId)
    const customerNumber = computed(
      () => user.value?.organisation?.customerNumber
    )

    onMounted(async () => {
      await loadDeliveryManagementDetails(organisationId.value)
    })

    const onUpdateQuery = (filters: IDeliveryManagementFilters) => {
      // always provide full filters config to check tab change
      const _filters = {
        ...getFiltersFromQuery.value,
        ...filters,
      }
      updateQuery(_filters)
    }

    const onTransactionSelected = (transactionNumber: string) => {
      updateQuery({
        searchInput: transactionNumber,
      } as IDeliveryManagementFilters)
    }

    const onCSVExport = () => {
      generateCSVExport(organisationId.value)
    }

    const onPDFDownload = (documents: IDeliveryDocument[]) => {
      const document = documents
        ?.filter(
          (item: IDeliveryDocument) =>
            item.fileType === DeliveryFileType.WeightNote
        )
        .find((item: IDeliveryDocument) =>
          item.filePath.includes(`${customerNumber.value}-`)
        )

      if (!document) return

      handlePDFDownload(document)
    }

    const aggregatedTags = computed(
      () => getDeliveriesAggregateInfo.value?.aggs?.materialCodes
    )

    return {
      deliveriesAggregateInfo: getDeliveriesAggregateInfo,
      deliveriesItems: getDeliveriesItems,
      defaultFilters: getDefaultFilters(),
      isLoading: getLoading,
      filtersFromQuery: getFiltersFromQuery,
      next: getLastEvaluatedKey,
      aggregatedTags,
      onUpdateQuery,
      onTransactionSelected,
      onCSVExport,
      onPDFDownload,
      user,
    }
  },
})
