







































































import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import {
  IDeliveriesAggregate,
  IDeliveryItem,
  IDeliveryMeta,
} from '@/components/delivery/DeliveryManagement/DeliveryManagementPage/composable'
import { DeliveryManagementTable } from '@/components/delivery/DeliveryManagement/DeliveryManagementTables'
import { DeliverySystemSyncStatus, DeliveryRawSyncStatus } from '@/enums'
import { deliveryRawStatusColour as deliveryStatusColour } from '@/utils/utils'
import { appConfig } from '@/config/config'
import { IUser } from '@/interfaces'
import { LoadMoreButton } from '@/components/molecules/LoadMoreButton'

export default defineComponent({
  components: {
    LoadMoreButton,
    DeliveryManagementTable,
  },
  props: {
    deliveriesAggregate: {
      type: Object as PropType<IDeliveriesAggregate>,
      default: () => null,
    },
    deliveriesItems: {
      type: Array as PropType<IDeliveryItem[]>,
      default: () => null,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: () => false,
    },
    user: {
      type: Object as PropType<IUser>,
      default: () => {},
    },
    next: {
      type: Number as PropType<number>,
      default: () => 0,
    },
  },
  setup(props, { emit, root }) {
    const route = root.$options.router?.currentRoute
    const { query } = route || {}

    const hasNoValues = (i: string | IDeliveryMeta | IDeliveryItem): boolean =>
      Number(i) === 0
    const statusWithValues = props.deliveriesItems?.filter(hasNoValues) || []
    const organisationId = computed(() => props.user?.organisationId)
    const customerNumber = computed(
      () => props.user?.organisation?.customerNumber ?? ''
    )

    const activeStatus = ref(
      statusWithValues.length === 1
        ? statusWithValues[0].status
        : query?.status || DeliverySystemSyncStatus.Unplanned
    )

    const isLoadMoreButtonVisible = computed(
      () =>
        props.deliveriesAggregate?.total >=
        props.next + appConfig.deliveriesLimit
    )

    const onTabClick = (status: DeliverySystemSyncStatus) => {
      activeStatus.value = status

      emit('query', { status })
    }

    const handleIconByStatus = (status: DeliverySystemSyncStatus): string => {
      switch (status) {
        case DeliverySystemSyncStatus.Unplanned:
          return 'mdi-clock-outline'
        case DeliverySystemSyncStatus.Planned:
          return 'mdi-calendar-month-outline'
        case DeliverySystemSyncStatus.NotDelivered:
          return 'mdi-folder-remove-outline'
        default:
          return 'mdi-folder-star-outline'
      }
    }

    const isDisabled = (
      status: Record<DeliveryRawSyncStatus, IDeliveryMeta>
    ): boolean => Object.values(status).every(hasNoValues)

    const shouldDisplayTable = computed(
      () => activeStatus.value && props.deliveriesItems?.length
    )
    const hasCsvButtonOnTable = computed(
      () => activeStatus.value === DeliverySystemSyncStatus.Unloaded
    )
    const shouldDisplaySpecialTable = computed(
      () => activeStatus.value === DeliverySystemSyncStatus.Unplanned
    )

    const optionalTableFields = computed(() => {
      switch (activeStatus.value) {
        case DeliverySystemSyncStatus.Unloaded:
          return ['amount', 'carrier', 'download']
        case DeliverySystemSyncStatus.Planned:
          return ['timeSlot']
        case DeliverySystemSyncStatus.NotDelivered:
          return ['timeSlot', 'amount', 'carrier', 'licensePlate']
        default:
          return ['paymentCondition', 'amount']
      }
    })

    return {
      deliveryStatusColour,
      isLoadMoreButtonVisible,
      handleIconByStatus,
      onTabClick,
      activeStatus,
      isDisabled,
      organisationId,
      customerNumber,
      hasCsvButtonOnTable,
      optionalTableFields,
      shouldDisplayTable,
      shouldDisplaySpecialTable,
    }
  },
})
