






















































































import { LocaleCode } from '@/enums'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import moment from 'moment'
import { appConfig } from '@/config/config'

type IStringDates = (string | undefined)[] | null | undefined
type ITimestamps = (number | undefined)[] | null | undefined

interface IPosition {
  x: number
  y: number
}

const menuOffset: IPosition = {
  x: -20,
  y: 30,
}

// helpers

const fromTimestampToStrDate = (timestamp: number | undefined) =>
  timestamp
    ? moment.unix(timestamp).format(appConfig.date.queryDateFormat)
    : undefined
const fromStrDateToTimestamp = (
  date: string | undefined,
  isEndDate: boolean
) => {
  if (!date) return undefined
  const method = isEndDate ? 'endOf' : 'startOf'
  return moment(date)[method]('day').unix()
}

const fromRangeTimestampsToStrDates = (dates: ITimestamps) =>
  dates?.map((timestamp) => fromTimestampToStrDate(timestamp))
const fromRangeStrDatesToTimestamps = (dates: IStringDates) =>
  dates?.map((date, index) => fromStrDateToTimestamp(date, index > 0))

const getFormattedLabelTexts = (newDates: ITimestamps) => {
  if (!newDates || !newDates.length) {
    return ['-', '-']
  }
  return newDates.map((date: number | undefined) =>
    date ? moment.unix(date).format(appConfig.date.formatMomentDate) : '-'
  )
}

export default defineComponent({
  props: {
    // value for v-model [timestamp, timestamp]
    value: {
      type: Array as PropType<number[]>,
      default: () => null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showWeek: {
      type: Boolean,
      default: true,
    },
    restrictToMonth: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit, root, refs }) {
    const isMenuOpen = ref(false)
    const isFocused = ref(false)
    const tempValueForCancel = ref<(string | undefined)[] | null>()
    const labelText = ref(
      props.value ? getFormattedLabelTexts(props.value) : ['-', '-']
    )
    const menuPosition = ref<IPosition>({ x: 0, y: 0 })
    const pickerDate = ref<string | null>(null)

    const locale = root.$i18n?.locale ?? LocaleCode.DE

    // helpers

    const isMomentWithinMinMax = (momentDate: moment.Moment) =>
      momentDate.unix() >= (props.min ?? 0) &&
      momentDate.unix() <= (props.max ?? Infinity)

    const setMenuPosition = (position: IPosition) => {
      menuPosition.value.x = position.x + menuOffset.x
      menuPosition.value.y = position.y + menuOffset.y
    }

    // computed

    const minDate = computed(() => {
      if (!props.min) return undefined
      return fromTimestampToStrDate(props.min)
    })
    const maxDate = computed(() => {
      if (!props.max) return undefined
      return fromTimestampToStrDate(props.max)
    })

    const modelValue = computed({
      get: (): IStringDates => {
        if (!props.value) return null
        return fromRangeTimestampsToStrDates(props.value)
      },
      set: (newValue: IStringDates) => {
        const timestamps = fromRangeStrDatesToTimestamps(newValue)?.sort()
        emit('input', timestamps)
        labelText.value = getFormattedLabelTexts(timestamps)

        if (newValue?.[0] && newValue?.[1]) {
          emit('change', fromRangeStrDatesToTimestamps(newValue)?.sort())
          isMenuOpen.value = false
        }
      },
    })

    const weekNumbersLabel = computed(() => {
      if (!props.showWeek || !modelValue.value) return null

      const [startDate, endDate] = modelValue.value
      if (!startDate || !endDate) {
        return null
      }

      const startWeek = moment(startDate, 'YYYY-MM-DD').week()
      const endWeek = moment(endDate, 'YYYY-MM-DD').week()

      if (startWeek === endWeek) {
        return startWeek
      }
      return `${startWeek}-${endWeek}`
    })

    // watcher

    watch(isMenuOpen, (isNowOpen) => {
      if (isNowOpen) {
        tempValueForCancel.value = modelValue.value
      }
    })

    watch(pickerDate, (newValue, oldValue) => {
      // when there is an oldValue it means we're changing months on picker
      // if oldValue is null it means the picker is initialising
      if (props.restrictToMonth && oldValue) {
        modelValue.value = [undefined, undefined]
        const firstInput = refs.firstInput as HTMLInputElement
        firstInput.focus()
      }
    })

    // handlers

    const handleFakeFieldClick = () => {
      const firstInput = refs.firstInput as HTMLInputElement
      firstInput.focus()
    }

    const handleInputFocus = (isFocus: boolean, event: FocusEvent) => {
      isFocused.value = isFocus
      const input = event.target as HTMLInputElement
      if (isFocus) {
        const { x, y } = input.getBoundingClientRect()
        setMenuPosition({ x, y })
        input.select()
        setTimeout(() => {
          isMenuOpen.value = true
        }, 200)
      }
    }

    const handleInputChange = (inputIndex: 0 | 1, event: InputEvent) => {
      const input = event.target as HTMLInputElement
      const momentDate = moment(
        input.value,
        appConfig.date.formatMomentDate,
        true
      )
      const dateRangeIsUsable =
        input.value.length > 7 &&
        momentDate.isValid() &&
        isMomentWithinMinMax(momentDate)

      if (dateRangeIsUsable) {
        const newModel = modelValue.value ?? [undefined, undefined]
        newModel[inputIndex] = momentDate.format(appConfig.date.queryDateFormat)
        modelValue.value = newModel

        if (newModel[0] && newModel[1]) {
          emit('change', fromRangeStrDatesToTimestamps(newModel)?.sort())
          isMenuOpen.value = false
        }
      }
    }

    const handleClickClear = () => {
      modelValue.value = [undefined, undefined]
      emit('change', modelValue.value)
    }

    const handleDatePick = (dates: string[]) => {
      if (dates.length === 1) {
        const secondInput = refs.secondInput as HTMLInputElement
        secondInput?.focus()
      }
    }

    return {
      modelValue,
      isMenuOpen,
      menuPosition,
      isFocused,
      handleFakeFieldClick,
      handleInputFocus,
      handleInputChange,
      handleClickClear,
      handleDatePick,
      labelText,
      locale,
      weekNumbersLabel,
      weekStart: appConfig.date.firstDayOfWeek,
      minDate,
      maxDate,
      pickerDate,
    }
  },
})
