


















import { defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import { DatePicker } from '@/components/molecules/DatePicker'
import { DeliveryMatchingDialogConfirm } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDialogConfirm'
import { DialogType } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/composable'

export default defineComponent({
  name: 'DeliveryMatchingDatePicker',
  components: {
    DatePicker,
    DeliveryMatchingDialogConfirm,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const modal = ref(false)
    const date = ref()
    const currentDay = moment().unix()

    const handleDateChange = (value: number) => {
      date.value = value
      modal.value = true
    }

    const handleCancel = () => {
      modal.value = false
    }

    const handleExecution = () => {
      emit('change-date', date.value)
      modal.value = false
    }

    return {
      currentDay,
      modal,
      DialogType,
      handleDateChange,
      handleCancel,
      handleExecution,
    }
  },
})
