import { FilterType } from '@/enums'

export const normalizeFilterRules: { [name: string]: any } = {
  dueDate: { type: FilterType.Range },
  materialTypeCode: { type: FilterType.Input },
  search: { type: FilterType.Input },
  src: { type: FilterType.Input },
  dst: { type: FilterType.Input },
  status: { type: FilterType.Input },
}
