var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""},on:{"~click":function($event){return (function () { return _vm.$emit('open'); })($event)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('deliveryMatching.dialog.search'))+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('loading-spinner',{attrs:{"is-loading":_vm.isLoading}}),_c('v-card',[_c('v-data-iterator',{attrs:{"items":_vm.items,"sort-by":_vm.sortBy,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"toolbar",attrs:{"height":"auto","flat":"","light":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","full-width":false,"items":_vm.sortList,"label":_vm.$t('deliveryMatching.filters.sort.label')},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","offset-sm":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":[
                  {
                    text: _vm.$t('common.all'),
                    value: null,
                  } ].concat( _vm.filterByStatusList ),"label":_vm.$t('deliveryMatching.filters.statusFilter.label')},on:{"change":_vm.handleStatusFilter},model:{value:(_vm.filterByStatus),callback:function ($$v) {_vm.filterByStatus=$$v},expression:"filterByStatus"}})],1)],1)],1)]},proxy:true},{key:"default",fn:function(props){return [_c('ul',{staticClass:"list"},_vm._l((props.items),function(item){return _c('li',{key:item.id,staticClass:"list-item",on:{"click":function () { return _vm.handleFactoryDeliverySelect(item); }}},[_c('delivery-matching-draft',{attrs:{"is-select-list":"","participant":item,"type":_vm.DeliveryMatchingParticipants.Factory,"status":item.status || _vm.DeliveryMatchingStatus.NoMatch}}),_c('v-divider')],1)}),0)]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"no-data-warning"},[_vm._v(" "+_vm._s(_vm.$t('deliveryMatching.noEntries'))+" ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }