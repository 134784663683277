



































































import {
  ref,
  computed,
  defineComponent,
  watch,
  PropType,
  getCurrentInstance,
  Ref,
} from '@vue/composition-api'
import { DeliveryMatchingItemCard } from '@/components/delivery/DeliveryMatching/DeliveryMatchingItemCard'
import { DeliveryMatchingSelect } from '@/components/delivery/DeliveryMatching/DeliveryMatchingSelect'
import {
  DeliveryMatchingParticipants,
  DeliveryMatchingStatus,
  Emitter,
} from '@/enums'
import { IDeliveryDraft, IDeliveryMatch } from '@/interfaces'

export default defineComponent({
  name: 'DeliveryMatchingDialogMatch',
  components: { DeliveryMatchingItemCard, DeliveryMatchingSelect },
  props: {
    match: {
      type: Object as PropType<null | IDeliveryMatch>,
      default: () => ({}),
    },
    factoryDeliveries: {
      type: Array as PropType<null | IDeliveryDraft[]>,
      required: true,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isDialogVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance()?.proxy
    const status = ref(props.match?.status)
    const selectedDelivery: Ref<IDeliveryDraft | null> = ref(null)

    const isMatchCanBeDeleted = computed(
      () => !!(props.match?.factory && props.match?.supplier)
    )
    const selectedFactoryDelivery = computed(
      () => selectedDelivery.value || props.match?.factory
    )
    const isMatchCanBeCreated = computed(
      () =>
        status.value === DeliveryMatchingStatus.Manual &&
        props.match?.factory?.deliveryId !==
          selectedFactoryDelivery.value?.deliveryId
    )
    const filteredFactoryDeliveries = computed(() =>
      props.factoryDeliveries?.filter((delivery: IDeliveryDraft) => {
        const hasSameIdAsSelected =
          delivery.deliveryId === selectedFactoryDelivery.value?.deliveryId
        const hasSameDeliveryTypeAsSupplier =
          delivery.deliveryType === props.match?.supplier?.deliveryType

        return !hasSameIdAsSelected && hasSameDeliveryTypeAsSupplier
      })
    )

    const dialog = computed({
      get: () => props.isDialogVisible,
      set: () => {
        emit('close')
      },
    })

    watch(
      () => props.match,
      () => {
        status.value = props.match?.status
      },
      { deep: true }
    )

    const onFactoryDeliverySelect = (delivery: IDeliveryDraft) => {
      selectedDelivery.value = delivery
      status.value = DeliveryMatchingStatus.Manual
    }

    const onMatchCreate = async () => {
      const payload = {
        supplierId: props.match?.supplier?.deliveryId,
        factoryId: selectedFactoryDelivery.value?.deliveryId,
      }

      if (props.match?.factory) {
        emit('create-confirm', payload)
        return
      }

      emit('create', payload)
    }

    const onClose = () => {
      vm?.$emitter?.emit(Emitter.MatchingDeliveries, '')
      selectedDelivery.value = null
      emit('close')
    }

    return {
      dialog,
      status,
      onFactoryDeliverySelect,
      onMatchCreate,
      onClose,
      selectedFactoryDelivery,
      filteredFactoryDeliveries,
      isMatchCanBeDeleted,
      isMatchCanBeCreated,
      DeliveryMatchingParticipants,
      Emitter,
    }
  },
})
