

























import {
  ref,
  defineComponent,
  watch,
  PropType,
  Ref,
} from '@vue/composition-api'
import { DeliveryMatchingDialogMatch } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDialogMatch'
import { DeliveryMatchingDialogConfirm } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDialogConfirm'
import { IDeliveryDraft, IDeliveryMatch } from '@/interfaces'
import { DialogType } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/composable'
import { IDeliveryMatchCreatePayload } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'

export default defineComponent({
  name: 'DeliveryMatchingDialog',
  components: { DeliveryMatchingDialogMatch, DeliveryMatchingDialogConfirm },
  props: {
    match: {
      type: Object as PropType<null | IDeliveryMatch>,
      default: () => {},
    },
    factoryDeliveries: {
      type: Array as PropType<null | IDeliveryDraft[]>,
      required: true,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    handleMatchCreate: {
      type: Function as PropType<(index: number) => void>,
      required: true,
    },
    handleDialogClose: {
      type: Function as PropType<(index: number) => void>,
      required: true,
    },
    isDialogVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isMatchingDialogVisible: Ref<boolean> = ref(props.isDialogVisible)
    const isConfirmationDialogVisible: Ref<boolean> = ref(false)
    const confirmationDialogType: Ref<string> = ref('')
    const confirmationPayload: Ref<IDeliveryMatchCreatePayload | null> = ref(
      null
    )

    watch(
      () => props.isDialogVisible,
      (val: boolean) => {
        if (!val) {
          isConfirmationDialogVisible.value = val
        }

        isMatchingDialogVisible.value = props.isDialogVisible
      }
    )

    const toggleDialogsVisibility = () => {
      isMatchingDialogVisible.value = !isMatchingDialogVisible.value
      isConfirmationDialogVisible.value = !isConfirmationDialogVisible.value
    }

    const handleDeleteConfirmation = () => {
      toggleDialogsVisibility()
      confirmationDialogType.value = DialogType.Delete
    }

    const handleCreateConfirmation = (payload: IDeliveryMatchCreatePayload) => {
      toggleDialogsVisibility()
      confirmationDialogType.value = DialogType.Match
      confirmationPayload.value = payload
    }

    const handleExecution = () => {
      switch (confirmationDialogType.value) {
        case DialogType.Delete: {
          emit('delete', props?.match?.supplier.deliveryId)

          break
        }
        case DialogType.Match: {
          emit('create', confirmationPayload.value)

          break
        }
      }
    }

    return {
      isMatchingDialogVisible,
      isConfirmationDialogVisible,
      confirmationDialogType,
      handleDeleteConfirmation,
      handleCreateConfirmation,
      toggleDialogsVisibility,
      handleExecution,
    }
  },
})
