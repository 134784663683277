






















import { defineComponent, PropType } from '@vue/composition-api'
import { IDeliveryItem } from '../DeliveryManagementPage/composable'
import { FloatingMenu } from '@/components/molecules/FloatingMenu'

export default defineComponent({
  name: 'DeliveryManagementDocuments',
  components: {
    FloatingMenu,
  },
  props: {
    delivery: {
      type: Object as PropType<IDeliveryItem>,
      default: () => {},
    },
  },
})
