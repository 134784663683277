

























































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TransactionMainInfo } from '@/components/common/TransactionMainInfo'
import { DeliveryFileType } from '@/enums'
import { IDeliveryDocument, IDeliveryFulfillment } from '@/interfaces'
import { IDeliveryItem } from '@/components/delivery/DeliveryManagement/DeliveryManagementPage/composable'
import AddressComponent from '@/components/common/AddressComponent.vue'
import { DeliveryManagementDocuments } from '@/components/delivery/DeliveryManagement/DeliveryManagementDocuments'
import { ITransaction } from '@/modules/transaction/resources'

type IOptionalFields =
  | 'amount'
  | 'carrier'
  | 'timeSlot'
  | 'paymentCondition'
  | 'licensePlate'
  | 'download'

interface IUnplannedDeliveries {
  deliveries: IDeliveryFulfillment
  transaction: ITransaction
}

export default defineComponent({
  components: {
    TransactionMainInfo,
    AddressComponent,
    DeliveryManagementDocuments,
  },
  props: {
    isSpecialTable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<IDeliveryItem[] | IUnplannedDeliveries[]>,
      default: () => null,
    },
    customerNumber: {
      type: Number,
      default: 0,
    },
    organisationId: {
      type: String,
      default: '',
    },
    optionalFields: {
      type: Array as PropType<IOptionalFields[]>,
      default: () => [],
    },
    hasCsvButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const tableHeaderKeys = [
      'deliveryDate',
      'transactionNumber',
      'tradingPartner',
    ]

    if (props.optionalFields?.includes('timeSlot')) {
      tableHeaderKeys.push('timeSlot')
    }

    tableHeaderKeys.push('deliveryNumber')
    tableHeaderKeys.push('material')
    tableHeaderKeys.push('loadingStation')
    tableHeaderKeys.push('unloadingStation')

    if (props.optionalFields?.includes('paymentCondition')) {
      tableHeaderKeys.push('paymentCondition')
    }
    if (props.optionalFields?.includes('amount')) {
      tableHeaderKeys.push('amount')
    }
    if (props.optionalFields?.includes('carrier')) {
      tableHeaderKeys.push('carrier')
    }
    if (props.optionalFields?.includes('licensePlate')) {
      tableHeaderKeys.push('licensePlate')
    }
    if (props.optionalFields?.includes('download')) {
      tableHeaderKeys.push('download')
    }

    const tableHeaders = tableHeaderKeys.map((key) => ({
      text:
        key === 'download' ? '' : root.$i18n?.t(`deliveryManager.table.${key}`),
      value: key,
      sortable: key === 'transactionNumber',
    }))

    const isWeightNotesDocumentsVisible = (documents: IDeliveryDocument[]) =>
      documents.some(
        (item: IDeliveryDocument) =>
          item?.fileType === DeliveryFileType.WeightNote
      )

    const transactions = computed(() => {
      if (props.isSpecialTable && props.items[0]?.transaction) {
        const items = props.items as IUnplannedDeliveries[]
        return items.map(({ transaction, deliveries }) => ({
          ...transaction,
          deliveries,
        }))
      }
      return []
    })

    const getTransactionNumber = (delivery: IDeliveryItem) => {
      if (props.isSpecialTable) {
        return ''
      }

      const { transactionNumber } = delivery?.transaction || {}
      const transactionNums = Array.isArray(transactionNumber)
        ? transactionNumber
        : [transactionNumber, transactionNumber]

      return transactionNums.find((id: string) =>
        id?.includes(`${props.customerNumber}-`)
      )
    }

    const getPartnerInfo = (delivery: IDeliveryItem) => {
      const partner = [delivery.buyer, delivery.seller].find(
        (participant) => participant.organisationId !== props.organisationId
      )
      if (partner) {
        return [partner.name, partner.contactPerson]
      }
      return []
    }

    const itemsPlusTransactionNumber = computed(() => {
      if (props.isSpecialTable) {
        return []
      }
      return (props.items as IDeliveryItem[]).map((item) => ({
        ...item,
        transactionNumber: getTransactionNumber(item),
      }))
    })

    return {
      tableHeaders,
      isWeightNotesDocumentsVisible,
      transactions,
      itemsPlusTransactionNumber,
      getPartnerInfo,
    }
  },
})
