





















import { defineComponent, PropType } from '@vue/composition-api'
import { DeliveryMatchingDraft } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDraft'
import { DeliveryMatchingStatusBadge } from '@/components/delivery/DeliveryMatching/DeliveryMatchingStatusBadge'
import { DeliveryMatchingParticipants, DeliveryMatchingStatus } from '@/enums'
import { IDeliveryDraft } from '@/interfaces'

export default defineComponent({
  name: 'DeliveryMatchingItemCard',
  components: { DeliveryMatchingDraft, DeliveryMatchingStatusBadge },
  props: {
    participant: {
      type: Object as PropType<null | IDeliveryDraft>,
      default: null,
    },
    type: {
      type: String as PropType<DeliveryMatchingParticipants>,
      required: true,
    },
    status: {
      type: String as PropType<DeliveryMatchingStatus>,
      default: DeliveryMatchingStatus.NoMatch,
    },
  },
})
