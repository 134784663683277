



























import { computed, defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import { LocaleCode } from '@/enums'
import { appConfig } from '@/config/config'

export default defineComponent({
  name: 'DatePicker',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: undefined,
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  setup(props, { emit, root }) {
    const menu = ref(false)
    const date = computed({
      get(): Date | null {
        if (!props.value) {
          return null
        }
        return moment.unix(props.value).toDate()
      },
      set(date: Date | null) {
        if (date === null) {
          return emit('input', undefined)
        }
        emit('input', moment(date, appConfig.date.queryDateFormat).unix())
      },
    })
    const minDate = computed(() => {
      if (!props.min) return undefined
      return moment.unix(props.min).format(appConfig.date.queryDateFormat)
    })
    const maxDate = computed(() => {
      if (!props.max) return undefined
      return moment.unix(props.max).format(appConfig.date.queryDateFormat)
    })

    const locale = root.$i18n?.locale ?? LocaleCode.DE

    const onChange = (value: string) => {
      emit('change', moment(value, appConfig.date.queryDateFormat).unix())
    }

    return {
      menu,
      date,
      minDate,
      maxDate,
      onChange,
      weekStart: appConfig.date.firstDayOfWeek,
      locale,
    }
  },
})
