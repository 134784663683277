import {
  reactive,
  computed,
  SetupContext,
  getCurrentInstance,
} from '@vue/composition-api'
import moment from 'moment'
import omit from 'lodash/omit'
import {
  IFetchQuery,
  getDeliveryManagementDetails,
  fetchCSVExportContents,
  downloadPDF,
} from './resources'
import {
  fileDownload,
  filterNormalizer,
  formatDate,
  toUnixTime,
} from '@/utils/utils'
import { normalizeFilterRules } from './rules'
import {
  DeliverySystemSyncStatus,
  DeliveryRawSyncStatus,
  OfferType,
} from '@/enums'
import { appConfig } from '@/config/config'
import { IParticipant } from '@/modules/transaction/resources'
import { openUrl } from '@/utils/DocumentUtil/DocumentUtil'
import { IDeliveryDocument } from '@/interfaces'

export interface IDeliveryMeta {
  deliveriesCount: number
  tons?: number
}

interface IDeliveryTransaction {
  transactionNumber: string[]
  paymentConditions?: string
}

export interface IDeliveryItem {
  deliveryNumber: number
  deliveryDate: number
  transactionNumber?: string | string[]
  status: DeliveryRawSyncStatus
  material: string
  documents: IDeliveryDocument[]
  seller: IParticipant
  buyer: IParticipant
  transaction: IDeliveryTransaction
}

interface IAggregatedData {
  materialCodes: string[]
}

export interface IDeliveriesAggregate {
  aggs: IAggregatedData
  total: number
  stats: Record<DeliveryRawSyncStatus, IDeliveryMeta>
}

export interface IDeliveryManagement {
  meta: IDeliveriesAggregate
  items: IDeliveryItem[]
}

export interface IDeliveryManagementFilters {
  [name: string]: any
  materialTypeCode?: string[]
  dateRange: [string | undefined, string | undefined]
  searchInput?: string
  tradeType?: OfferType[]
  lastEvaluatedKey?: number | boolean
}

export type IDeliveriesGroupedByStatus = Record<
  DeliverySystemSyncStatus | string,
  IDeliveryItem[]
>

interface IDeliveryManagementState {
  deliveries: IDeliveryManagement | null
  query: IFetchQuery | null
  filters: IDeliveryManagementFilters
  isLoading: boolean
  lastEvaluatedKey: number
}

export default function useDeliveryManagement(context: SetupContext) {
  const vm = getCurrentInstance()?.proxy
  const router = context.root.$options.router
  const currentDate = moment()

  const getDefaultFilters = () =>
    ({
      searchInput: '',
      dateRange: [
        `${currentDate.clone().startOf('week').unix()}`,
        `${currentDate.clone().endOf('week').unix()}`,
      ],
      materialTypeCode: [],
      tradeType: [OfferType.Buy, OfferType.Sell],
    } as IDeliveryManagementFilters)

  const state = reactive({
    deliveries: null,
    query: null,
    isLoading: false,
    filters: Object.assign(getDefaultFilters(), {
      ...omit(router?.currentRoute.query, 'lastEvaluatedKey'),
    }),
    lastEvaluatedKey: 0,
  } as IDeliveryManagementState)

  const setDeliveries = (
    deliveries: IDeliveryManagement | null,
    next?: boolean
  ) => {
    // (next == true) -> load more items
    if (deliveries && next) {
      state.deliveries = {
        ...deliveries,
        items: [...(state.deliveries?.items || []), ...deliveries.items],
      }
    } else {
      state.deliveries = deliveries
    }
  }

  const setIsLoading = (isLoading: boolean) => {
    state.isLoading = isLoading
  }

  const setFiltersFromQuery = (filters: IDeliveryManagementFilters) => {
    const filtersFromQuery = Object.assign({}, filters)

    if (filters.lastEvaluatedKey === true) {
      setLastEvaluatedKey()
      filtersFromQuery.lastEvaluatedKey = state.lastEvaluatedKey
    }

    state.filters = {
      ...getDefaultFilters(),
      ...state.filters,
      ...filtersFromQuery,
    }
  }

  const setLastEvaluatedKey = () => {
    state.lastEvaluatedKey =
      Number(state.lastEvaluatedKey) + appConfig.deliveriesLimit
  }

  const loadDeliveryManagementDetails = async (
    organisationId: string,
    query?: IFetchQuery,
    next?: boolean
  ) => {
    try {
      setIsLoading(true)
      const response = (await getDeliveryManagementDetails(
        organisationId,
        query || { ...omit(router?.currentRoute.query, 'lastEvaluatedKey') }
      )) as IDeliveryManagement

      setDeliveries(response, next)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const generateCSVExport = async (organisationId: string) => {
    // query and date format
    const dateRangeFromQuery = router?.currentRoute.query.dateRange as string[]
    const dates = dateRangeFromQuery?.map(Number) ?? []
    const dateFormatted = dates.map((time: number) => {
      const unixTimestamp = toUnixTime(time)
      return formatDate(unixTimestamp)
    })
    const queryToSend = { dateRange: dateRangeFromQuery.join(',') }

    // file name
    const pageName = vm?.$i18n.t('deliveryManager.title')
    const sectionName = vm?.$i18n.t('deliveryManager.status.unloaded')
    const filename = `${pageName}-${sectionName} ${dateFormatted.join('-')}.csv`

    // fetch csv contents
    try {
      const csvData = await fetchCSVExportContents(organisationId, queryToSend)

      // finally, download contents as file
      fileDownload(filename, csvData)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handlePDFDownload = async (document: IDeliveryDocument) => {
    const deliveryId = document.filePath.split('/').pop()
    const fileName = `Wiegenote-${deliveryId}.pdf`
    try {
      const filePath = `${document.filePath}/Wiegenote.pdf`
      const { url } = await downloadPDF(filePath)
      const pdf = await fetch(url)
      const blob = await pdf.blob()

      openUrl(window.URL.createObjectURL(blob), true, fileName)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const user = computed(() => context.root.$store.getters['auth/getUser'])

  const getDeliveriesAggregateInfo = computed(() => state.deliveries?.meta)
  const getDeliveriesItems = computed(() => state.deliveries?.items)
  const getLoading = computed(() => state.isLoading)
  const getQueryFromFilters = computed(() =>
    filterNormalizer(state.filters, normalizeFilterRules)
  )
  const getFiltersFromQuery = computed(() => state.filters)
  const getLastEvaluatedKey = computed(() => state.lastEvaluatedKey)

  const updateQuery = async (filters: IDeliveryManagementFilters) => {
    const prevStatus = state?.filters?.status
    setFiltersFromQuery(filters)

    // flush lastEvaluatedKey on tab change
    if (prevStatus !== filters?.status) {
      delete state.filters.lastEvaluatedKey
      state.lastEvaluatedKey = 0
      delete filters?.lastEvaluatedKey
    }

    await router?.push({
      query: getQueryFromFilters.value,
    })

    await loadDeliveryManagementDetails(
      user?.value?.organisationId,
      router?.currentRoute.query,
      !!filters.lastEvaluatedKey
    )
  }

  return {
    user,
    loadDeliveryManagementDetails,
    updateQuery,
    generateCSVExport,
    handlePDFDownload,
    getDeliveriesAggregateInfo,
    getDeliveriesItems,
    getLoading,
    getQueryFromFilters,
    getFiltersFromQuery,
    getLastEvaluatedKey,
    getDefaultFilters,
  }
}
