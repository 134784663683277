import { FilterType } from '@/enums'

export const normalizeFilterRules: { [name: string]: any } = {
  dateRange: { type: FilterType.Range },
  materialTypeCode: { type: FilterType.List },
  searchInput: { type: FilterType.Input },
  tradeType: { type: FilterType.List },
  status: { type: FilterType.Input },
  lastEvaluatedKey: { type: FilterType.Input },
}
