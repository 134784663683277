










































import {
  ComputedRef,
  defineComponent,
  getCurrentInstance,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { DeliveryMatchingFilters } from '@/components/delivery/DeliveryMatching/DeliveryMatchingFilters'
import { DeliveryMatchingList } from '@/components/delivery/DeliveryMatching/DeliveryMatchingList'
import { DeliveryMatchingDialog } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDialog'
import useDeliveryMatching from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/composable'
import { DeliveryMatchingStatus, Emitter } from '@/enums'
import {
  IDeliveryMatchCreatePayload,
  IDeliveryMatchingFilters,
} from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'
import { IDeliveryMatch } from '@/interfaces'
import { PageTitle } from '@/components/atoms/PageTitle'

interface IChangeCommentEmitPayload {
  id: string
  comment: string
}

interface IChangeDateEmitPayload {
  id: string
  alteredDueDate: number
}

export default defineComponent({
  name: 'DeliveryMatchingPage',
  components: {
    DeliveryMatchingFilters,
    DeliveryMatchingList,
    DeliveryMatchingDialog,
    PageTitle,
  },
  setup() {
    const vm = getCurrentInstance()?.proxy
    const {
      getMatches,
      getIsLoadingList,
      getIsLoadingChange,
      getFactoryDeliveries,
      getMaterialTypeCodes,
      updateMatch,
      updateMultipleMatches,
      sendConfirmedMatchesDocuments,
      loadMatches,
      loadMatchesList,
      deleteMatch,
      createMatch,
      updateQuery,
      getHowManyChecked,
      getIsChecked,
      getSelectedMatch,
      getFiltersFromQuery,
      getIsDisabled,
      getTransportCompaniesList,
      setSelectedDeliveryId,
      handleToggleCheck,
      handleToggleAllChecks,
      handleCSVExportSelected,
      handleCSVItemExport,
      handlePDFItemDownload,
    } = useDeliveryMatching()
    const isDialogVisible = ref(false)

    const toggleDialogVisibility = (factoryId: string | undefined) => {
      if (typeof factoryId === 'string') {
        setSelectedDeliveryId(factoryId)
        isDialogVisible.value = true
      }
    }

    const onMatchDelete = async (id: string) => {
      await deleteMatch(id)
      onMatchingDialogClose()
      await loadMatches()
    }

    const onMatchCreate = async (payload: IDeliveryMatchCreatePayload) => {
      const { factoryId } = payload
      const match = getMatches.value.find(
        (match: IDeliveryMatch) => match.factory?.deliveryId === factoryId
      )

      if (
        match &&
        match.supplier &&
        match.status !== DeliveryMatchingStatus.NoMatch
      ) {
        await deleteMatch(match.supplier?.deliveryId)
      }

      await createMatch(payload)
      onMatchingDialogClose()
      setSelectedDeliveryId('')
      await loadMatches()
    }

    const onMatchingDialogClose = () => {
      isDialogVisible.value = false
    }

    const onFactoryListOpen = async () => {
      await loadMatchesList()
    }

    onMounted(async () => {
      await loadMatches()
      vm?.$emitter.on(Emitter.MatchingDeliveries, toggleDialogVisibility)
    })

    onUnmounted(() => {
      vm?.$emitter.off(Emitter.MatchingDeliveries, toggleDialogVisibility)
    })

    const handleUpdateFilters = async (
      filters: ComputedRef<IDeliveryMatchingFilters>
    ) => {
      await updateQuery(filters.value)
    }

    const handleConfirmMatch = async (id: string) => {
      await updateMatch(id, { status: DeliveryMatchingStatus.Confirmed })
      await loadMatches()
    }

    const handleConfirmSelectedMatches = async () => {
      await updateMultipleMatches()
      await loadMatches()
    }

    const handleChangeComment = async ({
      id,
      comment,
    }: IChangeCommentEmitPayload) => {
      await updateMatch(id, { comment })
      await loadMatches()
    }

    const handleChangeDate = async ({
      id,
      alteredDueDate,
    }: IChangeDateEmitPayload) => {
      await updateMatch(id, { alteredDueDate })
      await loadMatches()
    }

    return {
      matches: getMatches,
      factoryDeliveries: getFactoryDeliveries,
      isLoadingList: getIsLoadingList,
      isLoadingChange: getIsLoadingChange,
      howManyChecked: getHowManyChecked,
      materialTypeCodes: getMaterialTypeCodes,
      selectedMatch: getSelectedMatch,
      filters: getFiltersFromQuery,
      isDisabled: getIsDisabled,
      transportCompaniesList: getTransportCompaniesList,
      isDialogVisible,
      isChecked: getIsChecked,
      handleToggleCheck,
      handleToggleAllChecks,
      onMatchDelete,
      onMatchCreate,
      onMatchingDialogClose,
      onFactoryListOpen,
      handleUpdateFilters,
      handleConfirmMatch,
      handleChangeComment,
      handleChangeDate,
      handleConfirmSelectedMatches,
      sendConfirmedMatchesDocuments,
      handleCSVExportSelected,
      handleCSVItemExport,
      handlePDFItemDownload,
    }
  },
})
