







































































import {
  defineComponent,
  ref,
  watch,
  computed,
  PropType,
} from '@vue/composition-api'
import isEqual from 'lodash/isEqual'
import OfferTypesCheckboxGroup from '@/components/common/OfferTypesCheckboxGroup.vue'
import MaterialTypeTags from '@/components/common/MaterialTypeTags.vue'
import { IDeliveryManagementFilters } from '@/components/delivery/DeliveryManagement/DeliveryManagementPage/composable'
import { DateRange } from '@/components/molecules/DateRange'
import { TextField } from '@/components/molecules/TextField'
import { Tooltip } from '@/components/common/Tooltip'

export default defineComponent({
  components: {
    DateRange,
    OfferTypesCheckboxGroup,
    MaterialTypeTags,
    Tooltip,
    TextField,
  },
  props: {
    filtersFromQuery: {
      type: Object as PropType<IDeliveryManagementFilters>,
      default: () => {},
    },
    defaultFilters: {
      type: Object as PropType<IDeliveryManagementFilters>,
      default: () => {},
    },
    aggregatedTags: {
      type: Array as PropType<string[]>,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const filters = ref(props.filtersFromQuery)

    watch(
      () => props.filtersFromQuery,
      () => {
        filters.value = props.filtersFromQuery
      },
      { deep: true }
    )

    const handleChange = () => {
      emit('filter', filters.value)
    }

    const handleResetFilters = () => {
      filters.value = props.defaultFilters

      emit('filter', filters.value)
    }

    const hasSelectedFilters = computed(
      () =>
        !isEqual(
          props.defaultFilters,
          JSON.parse(JSON.stringify(props.filtersFromQuery))
        )
    )

    return {
      filters,
      handleChange,
      hasSelectedFilters,
      handleResetFilters,
    }
  },
})
