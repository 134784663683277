import { computed, getCurrentInstance, reactive } from '@vue/composition-api'
import moment from 'moment'
import { IDeliveryMatch } from '@/interfaces'
import {
  getDeliveryMatches,
  createDeliveryMatch,
  confirmDeliveryMatch,
  deleteDeliveryMatch,
  IDeliveryMatchCreatePayload,
  IDeliveryMatchConfirmPayload,
  IDeliveryMatchingFilters,
  confirmMultipleDeliveryMatches,
  fetchCSVExport,
  sendDeliveryMatchesDocuments,
  transportCompaniesList,
  ITransportCompany,
} from './resources'
import {
  fileDownload,
  filterNormalizer,
  formatDate,
  toUnixTime,
} from '@/utils/utils'
import { normalizeFilterRules } from './rules'
import { downloadFile, openUrl } from '@/utils/DocumentUtil/DocumentUtil'
import { S3DocumentType } from '@/enums'

interface IDeliveryMatchingState {
  matches: IDeliveryMatch[]
  monthDeliveriesList: IDeliveryMatch[]
  selectedDeliveryId: string | null
  filters: IDeliveryMatchingFilters
  isLoading: {
    list: boolean
    change: boolean
  }
  checkedItems: Set<string>
  transportCompaniesList: ITransportCompany[]
}

const JASS_FIBRE_ORG_ID = 'aa21e726-3ca7-479c-9e9b-8a5d3c68fa4d'

export enum DialogType {
  Delete = 'delete',
  Match = 'match',
  Date = 'date',
}

export enum DeliveryType {
  DAP = 'DAP',
  EXW = 'EXW',
}

export default function useDeliveryMatching() {
  const vm = getCurrentInstance()?.proxy

  const state = reactive({
    matches: [],
    monthDeliveriesList: [],
    isLoading: {
      list: false,
      change: false,
    },
    selectedDeliveryId: null,
    filters: vm?.$router.currentRoute.query || {},
    checkedItems: new Set(),
    transportCompaniesList,
  } as IDeliveryMatchingState)

  const isGlobalAdmin = computed(
    () => vm?.$root.$store.getters['auth/getIsGlobalAdmin']
  )

  // internal setters

  const setDraftDeliveries = (deliveries: IDeliveryMatch[] = []) => {
    state.matches = deliveries
  }

  const setIsLoadingList = (isLoading: boolean) => {
    state.isLoading.list = isLoading
  }
  const setIsLoadingChange = (isListLoading: boolean) => {
    state.isLoading.change = isListLoading
  }

  const setSelectedDeliveryId = (selectedDeliveryId: string | null) => {
    state.selectedDeliveryId = selectedDeliveryId
  }

  const setFiltersFromQuery = (filters: IDeliveryMatchingFilters) => {
    state.filters = { ...filters }
  }

  const setCheckedItems = (items: Set<string>) => {
    state.checkedItems = items
  }

  const resetCheckedItems = () => {
    setCheckedItems(new Set())
  }

  const setMonthDeliveriesList = (deliveries: IDeliveryMatch[] = []) => {
    state.monthDeliveriesList = deliveries
  }

  // external handlers

  const loadMatches = async () => {
    try {
      setIsLoadingList(true)
      const response = await getDeliveryMatches(getFiltersFromQuery.value)
      setDraftDeliveries(response.items)

      resetCheckedItems()
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    } finally {
      setIsLoadingList(false)
    }
  }

  const loadMatchesList = async () => {
    const filtersDueDate = getFiltersFromQuery.value?.dueDate
    const query: IDeliveryMatchingFilters = {}

    if (filtersDueDate?.[0]) {
      const min = moment.unix(filtersDueDate[0]).startOf('month').unix()
      const max = moment.unix(filtersDueDate[0]).endOf('month').unix()

      query.dueDate = [min, max]
    }

    try {
      setIsLoadingChange(true)
      const monthList = await getDeliveryMatches(query)

      setMonthDeliveriesList(monthList.items)
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
    }
  }

  const createMatch = async (payload: IDeliveryMatchCreatePayload) => {
    try {
      setIsLoadingChange(true)
      await createDeliveryMatch(payload)
      setSelectedDeliveryId('')
    } catch (error) {
      // @ts-ignore
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
    }
  }

  const updateMatch = async (
    supplierId: string,
    payload: IDeliveryMatchConfirmPayload
  ) => {
    try {
      setIsLoadingChange(true)
      await confirmDeliveryMatch(supplierId, payload)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
    }
  }

  const updateMultipleMatches = async () => {
    try {
      setIsLoadingChange(true)
      const payload = { matchingIds: [...state.checkedItems] }
      await confirmMultipleDeliveryMatches(payload)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
    }
  }

  const sendConfirmedMatchesDocuments = async () => {
    try {
      setIsLoadingChange(true)
      const payload = { matchingIds: [...state.checkedItems] }
      await sendDeliveryMatchesDocuments(payload)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
      resetCheckedItems()
    }
  }

  const deleteMatch = async (matchId: string) => {
    try {
      setIsLoadingChange(true)
      await deleteDeliveryMatch(matchId)
      setSelectedDeliveryId('')
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setIsLoadingChange(false)
    }
  }

  const handleToggleCheck = (id: string) => {
    const method = state.checkedItems.has(id) ? 'delete' : 'add'
    const newSet = new Set(state.checkedItems)
    newSet[method](id)
    setCheckedItems(newSet)
  }

  const handleToggleAllChecks = () => {
    const newSetValues =
      state.checkedItems.size === state.matches.length
        ? []
        : state.matches.map((match) => match.id)
    setCheckedItems(new Set(newSetValues))
  }

  const handleCSVExportSelected = async () => {
    try {
      const { dueDate } = getQueryFromFilters.value
      const checkedIds = [...state.checkedItems].join(',')

      const csv = await fetchCSVExport(getQueryFromFilters.value, checkedIds)

      const dueDateFormatted =
        dueDate?.map((time: number) => {
          const unixTimestamp = toUnixTime(time)
          return formatDate(unixTimestamp)
        }) || []

      const filename = `${vm?.$i18n.t(
        `deliveryMatching.title`
      )} ${dueDateFormatted.join('-')}.csv`

      fileDownload(filename, csv)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const handleCSVItemExport = async (supplierId: string) => {
    try {
      const { url } = await downloadFile(
        JASS_FIBRE_ORG_ID,
        S3DocumentType.ConfirmedDelivery,
        `${supplierId}.csv`
      )

      openUrl(url, true)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const handlePDFItemDownload = async (factoryId: string) => {
    try {
      const { url } = await downloadFile(
        JASS_FIBRE_ORG_ID,
        S3DocumentType.ConfirmedDelivery,
        `${factoryId.replaceAll('|', '_')}.pdf`
      )

      openUrl(url, true)
    } catch (error: any) {
      throw new Error(error)
    }
  }

  // getters

  const getMatches = computed(() => state.matches)
  const getMonthDeliveriesList = computed(() => state.monthDeliveriesList)
  const getIsLoadingList = computed(() => state.isLoading.list)
  const getIsLoadingChange = computed(() => state.isLoading.change)
  const getFiltersFromQuery = computed(() => state.filters)
  const getFactoryDeliveries = computed(() =>
    (state.monthDeliveriesList || [])
      .filter((delivery: IDeliveryMatch) => !!delivery.factory)
      .map((delivery: IDeliveryMatch) => ({
        status: delivery.status,
        ...delivery.factory,
      }))
  )
  const getMaterialTypeCodes = computed(() => {
    const codes: string[] = []
    ;(state.matches || []).forEach((match) => {
      if (match.factory) {
        codes.push(match.factory.materialTypeCode)
      }
      if (match.supplier) {
        codes.push(match.supplier.materialTypeCode)
      }
    })
    return codes
  })
  const getQueryFromFilters = computed(() =>
    filterNormalizer(state.filters, normalizeFilterRules)
  )

  const getIsChecked = (id: string) => state.checkedItems.has(id)
  const getSelectedMatch = computed(() =>
    state.matches.find((draft) => draft.id === state.selectedDeliveryId)
  )
  const getHowManyChecked = computed(() => state.checkedItems.size)
  const getIsDisabled = computed(() => {
    if (isGlobalAdmin.value) return false

    const date = getFiltersFromQuery.value.dueDate

    if (!date) return false

    const currentMonth = moment().format('MMMM')
    const filterMonth = moment.unix(date[0] as number).format('MMMM')

    return currentMonth !== filterMonth
  })
  const getTransportCompaniesList = computed(() => state.transportCompaniesList)

  const updateQuery = async (filters: IDeliveryMatchingFilters) => {
    await vm?.$router.push({ query: {} })
    setFiltersFromQuery(filters)

    await vm?.$router.push({
      query: getQueryFromFilters.value,
    })

    await loadMatches()
  }

  return {
    getMatches,
    getIsLoadingList,
    getIsLoadingChange,
    getFiltersFromQuery,
    getHowManyChecked,
    getSelectedMatch,
    getMonthDeliveriesList,
    getIsChecked,
    getIsDisabled,
    getTransportCompaniesList,
    handleToggleCheck,
    handleToggleAllChecks,
    handleCSVExportSelected,
    handleCSVItemExport,
    handlePDFItemDownload,
    getFactoryDeliveries,
    getMaterialTypeCodes,
    setSelectedDeliveryId,
    loadMatches,
    loadMatchesList,
    createMatch,
    updateMatch,
    updateMultipleMatches,
    sendConfirmedMatchesDocuments,
    deleteMatch,
    updateQuery,
  }
}
