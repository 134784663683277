import HttpService, { HttpMethod } from '@/services/HttpService/HttpService'
import { IDeliveryMatch } from '@/interfaces'
import { DeliveryMatchingStatus } from '@/enums'
import pickBy from 'lodash/pickBy'

interface IDeliveryMatchMeta {
  count: number
}

interface IDeliveryMatchList extends IDeliveryMatchMeta {
  items: IDeliveryMatch[]
}

export interface IDeliveryMatchCreatePayload {
  factoryId: string
  supplierId: string
}

export interface IDeliveryMatchConfirmPayload {
  status?: DeliveryMatchingStatus
  comment?: string
  alteredDueDate?: number
}
export interface IDeliveryMatchConfirmMultiplePayload {
  matchingIds?: string[]
}

export interface IDeliveryMatchingFilters {
  search?: string
  materialTypeCode?: string
  dueDate?: [number | undefined, number | undefined]
  status?: DeliveryMatchingStatus
  dst?: string
  src?: string
}

export interface ITransportCompany {
  name: string
  email: string
}

const getSearchQuery = (query: IDeliveryMatchingFilters | undefined) => {
  const finalQuery: Record<string, any> = pickBy({ ...(query || {}) })

  if (finalQuery.searchStr) {
    finalQuery.search = `searchStr,${finalQuery.searchStr.toUpperCase()}`
    delete finalQuery.searchStr
  }

  if (finalQuery.search) {
    finalQuery.search = `searchStr,${finalQuery.search.toUpperCase()}`
  }

  if (finalQuery.dst) {
    finalQuery.dst = `destinationStr,${finalQuery.dst}`
  }

  if (finalQuery.src) {
    finalQuery.src = `sourceStr,${finalQuery.src}`
  }

  if (!finalQuery.dueDate?.filter(Boolean).length) {
    delete finalQuery.dueDate
  }

  return finalQuery
}

export const getDeliveryMatches = async (
  query?: IDeliveryMatchingFilters
): Promise<IDeliveryMatchList> => {
  return await HttpService.request({
    query: getSearchQuery(query),
    path: `/deliveries-matchings`,
  })
}

export const createDeliveryMatch = async (
  payload: IDeliveryMatchCreatePayload
): Promise<void> => {
  await HttpService.request({
    path: `/deliveries-matchings`,
    method: HttpMethod.Post,
    body: payload,
  })
}

export const confirmDeliveryMatch = async (
  id: string,
  payload: IDeliveryMatchConfirmPayload
): Promise<void> => {
  await HttpService.request({
    path: `/deliveries-matchings/${id}`,
    method: HttpMethod.Put,
    body: payload,
  })
}

export const confirmMultipleDeliveryMatches = async (
  payload: IDeliveryMatchConfirmMultiplePayload
): Promise<void> => {
  await HttpService.request({
    path: `/deliveries-matchings`,
    method: HttpMethod.Put,
    body: payload,
  })
}

export const sendDeliveryMatchesDocuments = async (
  payload: IDeliveryMatchConfirmMultiplePayload
): Promise<void> => {
  await HttpService.request({
    path: `/deliveries-matchings/send-emails`,
    method: HttpMethod.Post,
    body: payload,
  })
}

export const deleteDeliveryMatch = async (matchId: string): Promise<void> => {
  await HttpService.request({
    path: `/deliveries-matchings/${matchId}`,
    method: HttpMethod.Delete,
  })
}

export const fetchCSVExport = async (
  query: IDeliveryMatchingFilters,
  deliveryIds: string
) => {
  return await HttpService.request({
    path: `/deliveries-matchings/export-csv`,
    responseType: 'text',
    query: { ...getSearchQuery(query), selected: deliveryIds },
  })
}

export const transportCompaniesList: ITransportCompany[] = [
  {
    name: 'Containertrans Linke',
    email: 'dispo-containertranslinke@gmx.de',
  },
  {
    name: 'Fuhrbetrieb Dieter Schlegel',
    email: 'service@fuhrbetrieb-schlegel.de',
  },
  {
    name: 'PreZero',
    email: 'GreenCycle Selbstanlieferung',
  },
  {
    name: 'Transa',
    email: 'christina.wurbs@transa.de',
  },
  {
    name: 'Vogel Spedition',
    email: 'Info@vogel-fulda.de',
  },
  {
    name: 'ZimSped',
    email: 'sz@zimsped.de',
  },
]
