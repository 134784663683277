











































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { IDeliveryDraft } from '@/interfaces'
import {
  DeliveryMatchingStatus,
  DeliveryMatchingParticipants,
  Emitter,
  FileIconType,
} from '@/enums'
import { DeliveryMatchingDraft } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDraft'
import { DeliveryMatchingStatusBadge } from '@/components/delivery/DeliveryMatching/DeliveryMatchingStatusBadge'
import { DeliveryMatchingComment } from '@/components/delivery/DeliveryMatching/DeliveryMatchingComment'
import { FileIcon } from '@/components/atoms/FileIcon'
import { ITransportCompany } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'

export default defineComponent({
  components: {
    DeliveryMatchingDraft,
    DeliveryMatchingStatusBadge,
    DeliveryMatchingComment,
    FileIcon,
  },
  props: {
    factory: {
      type: Object as PropType<null | IDeliveryDraft>,
      default: null,
    },
    supplier: {
      type: Object as PropType<null | IDeliveryDraft>,
      default: null,
    },
    status: {
      type: String as PropType<DeliveryMatchingStatus>,
      default: DeliveryMatchingStatus.NoMatch,
    },
    value: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isTargetAddressVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isChecked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
    transportCompaniesList: {
      type: Array as PropType<ITransportCompany[]>,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const isConfirmable = computed(
      () =>
        props.factory &&
        props.supplier &&
        props.status !== DeliveryMatchingStatus.Confirmed
    )
    const isConfirmed = computed(
      () => props.status === DeliveryMatchingStatus.Confirmed
    )

    const handleToggleCheck = () => {
      emit('toggle-check')
    }
    const handleChangeComment = (comment: string) => {
      emit('change-comment', comment)
    }
    const handleChangeDate = (alteredDueDate: number) => {
      emit('change-date', alteredDueDate)
    }

    return {
      isConfirmable,
      handleToggleCheck,
      handleChangeComment,
      handleChangeDate,
      DeliveryMatchingParticipants,
      Emitter,
      FileIconType,
      isConfirmed,
    }
  },
})
