









import { defineComponent, PropType, computed } from '@vue/composition-api'
import { DeliveryMatchingStatus } from '@/enums'

export default defineComponent({
  name: 'DeliveryMatchingStatusBadge',
  props: {
    status: {
      type: String as PropType<DeliveryMatchingStatus>,
      default: DeliveryMatchingStatus.NoMatch,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const statusToShow = computed(
      () => props.status || DeliveryMatchingStatus.NoMatch
    )

    return {
      statusToShow,
    }
  },
})
