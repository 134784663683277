































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { IDeliveryDraft } from '@/interfaces'
import { DeliveryMatchingParticipants, DeliveryMatchingStatus } from '@/enums'
import AddressComponent from '@/components/common/AddressComponent.vue'
import { DeliveryMatchingStatusBadge } from '@/components/delivery/DeliveryMatching/DeliveryMatchingStatusBadge'
import { DeliveryMatchingDatePicker } from '@/components/delivery/DeliveryMatching/DeliveryMatchingDatePicker'
import moment from 'moment'

const idKeyMap = {
  [DeliveryMatchingParticipants.Factory]: 'deliveryDocNumber',
  [DeliveryMatchingParticipants.Supplier]: 'evNumber',
}

type IAddressTypeKey = 'destination' | 'source'

const addressKeyMap: Record<string, IAddressTypeKey> = {
  [DeliveryMatchingParticipants.Factory]: 'destination',
  [DeliveryMatchingParticipants.Supplier]: 'source',
}

export default defineComponent({
  name: 'DeliveryMatchingDraft',
  components: {
    AddressComponent,
    DeliveryMatchingStatusBadge,
    DeliveryMatchingDatePicker,
  },
  props: {
    participant: {
      type: Object as PropType<null | IDeliveryDraft>,
      default: null,
    },
    type: {
      type: String as PropType<DeliveryMatchingParticipants>,
      required: true,
    },
    isTargetAddressVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    status: {
      type: String as PropType<DeliveryMatchingStatus | null>,
      default: null,
    },
    overrideDate: {
      type: Number,
      default: null,
    },
    isSelectList: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { slots, emit }) {
    const idKey = computed(() => idKeyMap[props.type])

    // address-related logic

    const addressTypeKey = computed(() => {
      if (props.isTargetAddressVisible) {
        return 'destination'
      } else {
        return addressKeyMap[props.type]
      }
    })

    const address = computed(() => props.participant?.[addressTypeKey.value])

    const hasOverrideDate = computed(() => {
      if (!props.overrideDate || !props.participant) return false
      const overrideMoment = moment.unix(props.overrideDate)
      const factoryDateMoment = moment.unix(props.participant.dueDate)
      return factoryDateMoment.diff(overrideMoment, 'days') !== 0
    })
    const hasOverrideSupplierDate = computed(
      () => !!props.participant?.originalDueDate
    )
    const overrideMatchDate = computed(
      () => props.participant?.originalDueDate || props.overrideDate
    )

    const hasExtraContent = computed(() => !!slots.default?.().length)

    const isDataChangeable = computed(
      () => props.type === DeliveryMatchingParticipants.Supplier
    )

    const handleChangeDate = (alteredDueDate: number) => {
      emit('change-date', alteredDueDate)
    }

    return {
      idKey,
      addressTypeKey,
      address,
      hasExtraContent,
      hasOverrideDate,
      isDataChangeable,
      handleChangeDate,
      overrideMatchDate,
      hasOverrideSupplierDate,
    }
  },
})
