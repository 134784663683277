
















import { defineComponent, computed, PropType } from '@vue/composition-api'
import { ITransportCompany } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'
import { IDeliveryDraft } from '@/interfaces'
import { DeliveryType } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/composable'

export default defineComponent({
  name: 'DeliveryMatchingComment',
  props: {
    factory: {
      type: Object as PropType<null | IDeliveryDraft>,
      default: null,
    },
    comment: {
      type: String,
      default: '',
    },
    transportCompaniesList: {
      type: Array as PropType<ITransportCompany[]>,
      default: () => null,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props, { emit }) {
    const modelValue = computed({
      get() {
        const defaultValue =
          props.factory?.deliveryType === DeliveryType.DAP
            ? props.transportCompaniesList?.find(
                (item: ITransportCompany) => item.name === 'PreZero'
              )
            : ({} as ITransportCompany)

        return props.comment || defaultValue?.name
      },
      set(newValue: string | undefined) {
        emit('change', newValue)
      },
    })

    const fieldId = computed(() => `delivery-matching-comment-${Math.random()}`)

    const items = computed(() =>
      props.transportCompaniesList?.map((item) => item.name)
    )

    return { fieldId, modelValue, items }
  },
})
