var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isSpecialTable && _vm.transactions && _vm.transactions.length)?_c('article',_vm._l((_vm.transactions),function(transaction){return _c('div',{key:transaction.id,attrs:{"id":transaction.id}},[_c('router-link',{attrs:{"to":{
          name: 'transaction',
          params: { id: transaction.id },
        },"from":{
          hash: ("#" + (transaction.id)),
        }}},[_c('el-card',{staticClass:"card"},[(transaction)?_c('transaction-main-info',{attrs:{"list-view":"","transaction":transaction,"deliveries":transaction.deliveries}}):_vm._e()],1)],1)],1)}),0):(!_vm.isSpecialTable)?[(_vm.hasCsvButton)?_c('div',{staticClass:"top-button-bar"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function () { return _vm.$emit('csv-export'); }}},[_vm._v(" "+_vm._s(_vm.$t('common.exportCSV'))+" ")])],1):_vm._e(),(_vm.hasCsvButton)?_c('div',{staticClass:"divider"}):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.itemsPlusTransactionNumber,"headers":_vm.tableHeaders,"disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.deliveryDate",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("toUnixTime")(item.deliveryDate)))+" ")])]}},{key:"item.tradingPartner",fn:function(ref){
        var item = ref.item;
return _vm._l((_vm.getPartnerInfo(item)),function(partnerInfo){return _c('div',{key:partnerInfo},[_vm._v(" "+_vm._s(partnerInfo)+" ")])})}},{key:"item.loadingStation",fn:function(ref){
        var item = ref.item;
return [(item.seller)?_c('address-component',{attrs:{"address":item.seller.station}}):_vm._e()]}},{key:"item.unloadingStation",fn:function(ref){
        var item = ref.item;
return [(item.buyer)?_c('address-component',{attrs:{"address":item.buyer.station}}):_vm._e()]}},{key:"item.paymentCondition",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.transaction && item.transaction.paymentCondition)+" ")]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [(item.guaranteedUnitAmount && item.unitAmount)?[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.unitAmount))+" "),_c('span',[_vm._v("/")]),_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.guaranteedUnitAmount))+" ")]:_vm._e()]}},{key:"item.carrier",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.carrier && item.carrier.name)+" ")]}},{key:"item.download",fn:function(ref){
        var item = ref.item;
return [(_vm.isWeightNotesDocumentsVisible(item.documents))?_c('delivery-management-documents',{attrs:{"delivery":item},on:{"pdf-download":function($event){return _vm.$emit('pdf-download', item.documents)}}}):_vm._e()]}}])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }