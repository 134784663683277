





























































































import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import { DeliveryMatchingItem } from '@/components/delivery/DeliveryMatching/DeliveryMatchingItem'
import { LoadingSpinner } from '@/components/atoms/LoadingSpinner'
import { IDeliveryMatch } from '@/interfaces'
import { ITransportCompany } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/resources'

export default defineComponent({
  name: 'DeliveryMatchingList',
  components: {
    DeliveryMatchingItem,
    LoadingSpinner,
  },
  props: {
    matches: {
      type: Array as PropType<IDeliveryMatch[]>,
      default: [],
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLoadingChange: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    howManyChecked: {
      type: Number as PropType<number>,
      default: false,
    },
    isChecked: {
      type: Function as PropType<(index: number) => boolean>,
      default: () => false,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
    transportCompaniesList: {
      type: Array as PropType<ITransportCompany[]>,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const isAnyChecked = computed(() => props.howManyChecked > 0)
    const isAllChecked = computed(
      () => props.howManyChecked === props.matches.length
    )

    const isTargetAddressVisible = ref(false)

    const handleTargetAddressSwitchChange = () => {
      emit('toggle-address-visibility', isTargetAddressVisible.value)
    }
    const handleToggleAllChecks = () => {
      emit('toggle-all-checks')
    }
    const handleToggleCheck = (index: number) => {
      emit('toggle-check', index)
    }
    const handleChangeComment = (id: string, comment: string) => {
      emit('change-comment', { id, comment })
    }
    const handleChangeDate = (id: string, alteredDueDate: number) => {
      emit('change-date', { id, alteredDueDate })
    }
    const handleConfirmMatch = (id: string) => {
      emit('confirm', id)
    }
    const handleConfirmSelectedMatches = () => {
      emit('confirm-selected')
    }
    const handleSendSelectedMatchesDocuments = () => {
      emit('send-documents')
    }
    const handleCSVExportSelected = () => {
      emit('csv-export')
    }
    const handleCSVItemExport = (id: string) => {
      emit('csv-item-export', id)
    }
    const handlePDFItemDownload = (id: string) => {
      emit('pdf-item-download', id)
    }

    return {
      isAnyChecked,
      isAllChecked,
      isTargetAddressVisible,
      handleTargetAddressSwitchChange,
      handleToggleAllChecks,
      handleToggleCheck,
      handleChangeComment,
      handleChangeDate,
      handleConfirmMatch,
      handleConfirmSelectedMatches,
      handleSendSelectedMatchesDocuments,
      handleCSVExportSelected,
      handleCSVItemExport,
      handlePDFItemDownload,
    }
  },
})
