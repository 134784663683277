







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'pdf',
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      emit('click')
    }

    return { handleClick }
  },
})
