



































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { DialogType } from '@/components/delivery/DeliveryMatching/DeliveryMatchingPage/composable'
import MarkdownComponent from '@/components/common/MarkdownComponent.vue'

export default defineComponent({
  name: 'DeliveryMatchingConfirmPopup',
  components: { MarkdownComponent },
  props: {
    isDialogVisible: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    type: {
      type: String as PropType<DialogType>,
      required: true,
    },
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const dialog = computed(() => props.isDialogVisible)
    const dialogIcon = computed(() => {
      switch (props.type) {
        case DialogType.Delete:
          return 'mdi-exclamation-thick'
        case DialogType.Match:
          return 'mdi-link-variant-off'
        case DialogType.Date:
          return 'mdi-calendar-blank'
        default:
          return 'mdi-exclamation-thick'
      }
    })

    return {
      dialog,
      dialogIcon,
    }
  },
})
